import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "../../Shared";
import { VideoCard } from "../../Shared/VideoCard";

const query = graphql`
  {
    data: homesJson {
      sectionTwo {
        title
        videoUrl
      }
    }
  }
`;

export function SectionTwo() {
  const { data } = useStaticQuery(query);
  const { title, videoUrl } = data.sectionTwo;

  return (
    <section>
      <Container>
        <div className="space-y-16 py-12 lg:py-20">
          <div className="text-center flex">
            <h2 className="text-sm md:text-lg lg:text-xl mx-auto uppercase text-white font-serif tracking-widest leading-loose md:leading-loose lg:leading-loose xl:leading-loose 2xl:leading-loose xl:max-w-4xl">
              {title}
            </h2>
          </div>
          <VideoCard url={videoUrl} />
        </div>
      </Container>
    </section>
  );
}
