import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "../../Shared";
import { VideoCard } from "../../Shared/VideoCard";

const query = graphql`
  {
    data: homesJson {
      lore {
        title
        paragraphs
        videoUrl
      }
    }
  }
`;

export function Lore() {
  const { data } = useStaticQuery(query);
  const { title, paragraphs, videoUrl } = data.lore;

  return (
    <section>
      <Container>
        <div className="flex flex-col lg:flex-row gap-16 xl:gap-24 py-12 lg:py-20">
          <div className="lg:w-1/2 space-y-6">
            <h3 className="text-lg md:text-xl font-bold uppercase text-white font-serif tracking-widest leading-loose md:leading-loose lg:leading-loose xl:leading-loose 2xl:leading-loose">
              {title}
            </h3>
            <div className="space-y-4">
              {Object.keys(paragraphs).map((key) => (
                <p key={key} className="text-base md:text-lg text-white">
                  {paragraphs[key]}
                </p>
              ))}
            </div>
          </div>
          <div className="lg:w-1/2">
            <VideoCard url={videoUrl} />
          </div>
        </div>
      </Container>
    </section>
  );
}
