import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "../../Shared";
import { VideoCard } from "../../Shared/VideoCard";

const query = graphql`
  {
    data: homesJson {
      sectionSeven {
        title
        overline
        description
        secondTitle
        paragraphs
        videoUrl
      }
    }
  }
`;

export function SectionSeven() {
  const { data } = useStaticQuery(query);
  const { overline, title, description, secondTitle, paragraphs, videoUrl } =
    data.sectionSeven;

  return (
    <section>
      <Container>
        <div className="space-y-16 xl:space-y-24  py-12 lg:py-20">
          <div className="flex flex-col lg:flex-row gap-16 xl:gap-24">
            <div className="lg:w-1/2 space-y-12">
              <div className="space-y-6 text-center lg:text-left">
                <div>
                  <span className="uppercase block font-mono text-overline tracking-[3px] text-sm">
                    {overline}
                  </span>
                  <h2 className="text-2xl md:text-3xl font-bold uppercase text-white font-serif tracking-widest leading-loose md:leading-loose lg:leading-loose xl:leading-loose 2xl:leading-loose">
                    {title}
                  </h2>
                </div>
                <p className="text-lg md:text-xl text-white font-semibold">
                  {description}
                </p>
              </div>
              <div className="space-y-6">
                <h3 className="text-lg md:text-xl font-bold uppercase text-white font-serif tracking-widest leading-loose md:leading-loose lg:leading-loose xl:leading-loose 2xl:leading-loose">
                  {secondTitle}
                </h3>
                <div className="space-y-4">
                  {Object.keys(paragraphs).map((key) => (
                    <p key={key} className="text-base md:text-lg text-white">
                      {paragraphs[key]}
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div className="lg:w-1/2 lg:flex lg:items-center">
              <VideoCard url={videoUrl} />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
