import * as React from "react";
import {
  SectionOne,
  SectionTwo,
  SectionThree,
  SectionThre,
  SectionFive,
  SectionSix,
  SectionSeven,
  SectionNine,
  GamingEconomics,
} from "../components/Featured/Home";
import SEO from "../components/Featured/SEO";
import { Lore } from "../components/Featured/Home/Lore";

function IndexPage() {
  return (
    <div className="overflow-x-hidden">
      <SEO />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionThre />
      <Lore />
      {/* <SectionFour /> */}
      <SectionFive />
      <GamingEconomics />
      <SectionSix />
      <SectionSeven />
      {/* <SectionEight /> */}
      <SectionNine />
      {/* <SectionTen /> */}
      {/* <SectionTwelve /> */}
      {/* <SectionThirteen /> */}
      {/* <SectionEleven /> */}
    </div>
  );
}

export default IndexPage;
