import React from "react";
import PropTypes from "prop-types";

export function VideoCard({ url }) {
  return (
    <div className="relative p-2 aspect-video bg-second shadow-lg w-full">
      <div className="absolute inset-0 border-2 border-third" />
      <span className="absolute left-0 top-0 h-4 w-0.5 bg-red-500" />
      <span className="absolute left-0 bottom-0 h-4 w-0.5 bg-red-500" />
      <span className="absolute right-0 top-0 h-4 w-0.5 bg-red-500" />
      <span className="absolute right-0 bottom-0 h-4 w-0.5 bg-red-500" />
      <span className="absolute top-0 left-0 h-0.5 w-4 bg-red-500" />
      <span className="absolute top-0 right-0 h-0.5 w-4 bg-red-500" />
      <span className="absolute bottom-0 left-0 h-0.5 w-4 bg-red-500" />
      <span className="absolute bottom-0 right-0 h-0.5 w-4 bg-red-500" />
      <iframe
        src={url}
        title="Victory point trailer"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        className="aspect-video h-full w-full relative"
      />
    </div>
  );
}

VideoCard.propTypes = {
  url: PropTypes.string.isRequired,
};
