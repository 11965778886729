import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { Container } from "../../Shared";

const query = graphql`
  {
    data: homesJson {
      gamingEconomics {
        title
        paragraphs
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export function GamingEconomics() {
  const { data } = useStaticQuery(query);
  const { title, paragraphs, image } = data.gamingEconomics;
  const imagePlugin = getImage(image);

  return (
    <section>
      <Container>
        <div className="flex flex-col lg:flex-row gap-16 xl:gap-24 py-12 lg:py-20">
          <div className="lg:w-1/2 space-y-6">
            <h3 className="text-lg md:text-xl font-bold uppercase text-white font-serif tracking-widest leading-loose md:leading-loose lg:leading-loose xl:leading-loose 2xl:leading-loose">
              {title}
            </h3>
            <div className="space-y-4">
              {Object.keys(paragraphs).map((key) => (
                <p key={key} className="text-base md:text-lg text-white">
                  {paragraphs[key]}
                </p>
              ))}
            </div>
          </div>
          <div className="lg:w-1/2 lg:flex lg:items-center">
            <div className="relative">
              <GatsbyImage image={imagePlugin} alt={title} />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
