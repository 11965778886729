import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Keyboard, A11y } from "swiper";
import { Container, IconButton } from "../../Shared";
import { LeftArrow, RightArrow } from "../../Icons";

import "swiper/css";
import "swiper/css/navigation";

const query = graphql`
  {
    data: homesJson {
      sectionNine {
        cards {
          name
          description
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export function SectionNine() {
  const { data } = useStaticQuery(query);
  const { cards } = data.sectionNine;

  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <section>
      <Container>
        <div className="space-y-16 xl:space-y-24  py-12 lg:py-20">
          <div className="w-full h-full">
            <Swiper
              centeredSlides
              keyboard={{
                enabled: true,
              }}
              breakpoints={{
                640: {
                  slidesPerView: "1.5",
                  spaceBetween: 20,
                },
                1280: {
                  slidesPerView: "2",
                  spaceBetween: 20,
                },
              }}
              loop
              onSlideChange={(val) => setActiveSlide(val.realIndex)}
              modules={[Keyboard, A11y]}
            >
              {Object.keys(cards).map((key) => {
                const image = getImage(cards[key].image);
                return (
                  <SwiperSlide key={key}>
                    {({ isActive }) => (
                      <div
                        className={classNames(
                          "relative p-2 aspect-video bg-second shadow-lg",
                          isActive ? "grayscale-0" : "grayscale"
                        )}
                      >
                        <div className="absolute inset-0 border-2 border-third" />
                        <span className="absolute left-0 top-0 h-4 w-0.5 bg-red-500" />
                        <span className="absolute left-0 bottom-0 h-4 w-0.5 bg-red-500" />
                        <span className="absolute right-0 top-0 h-4 w-0.5 bg-red-500" />
                        <span className="absolute right-0 bottom-0 h-4 w-0.5 bg-red-500" />
                        <span className="absolute top-0 left-0 h-0.5 w-4 bg-red-500" />
                        <span className="absolute top-0 right-0 h-0.5 w-4 bg-red-500" />
                        <span className="absolute bottom-0 left-0 h-0.5 w-4 bg-red-500" />
                        <span className="absolute bottom-0 right-0 h-0.5 w-4 bg-red-500" />
                        <GatsbyImage
                          image={image}
                          alt={cards[key].name}
                          className="h-full"
                        />
                        <div className="absolute bottom-0 left-0 w-full p-4">
                          <div className="p-4 bg-gradient-to-br from-second/50 to-second/60 backdrop-blur-lg shadow-lg border-2 border-third/10 space-y-2">
                            <h4 className="text-xs md:text-sm text-center font-bold uppercase font-serif tracking-widest leading-loose">
                              {cards[key].name}
                            </h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </SwiperSlide>
                );
              })}
              <div
                slot="container-start"
                className="space-x-4 hidden lg:flex justify-end w-full mb-6"
              >
                <PrevButton />
                <NextButton />
              </div>
              <div
                slot="container-end"
                className="flex space-x-2 justify-center mt-4"
              >
                {Object.keys(cards).map((key) => (
                  <span
                    key={key}
                    className={classNames(
                      "w-2 h-2 transition-all ease-out duration-300",
                      activeSlide.toString() === key
                        ? "bg-red-500"
                        : "bg-red-500/20"
                    )}
                  />
                ))}
              </div>
            </Swiper>
          </div>
        </div>
      </Container>
    </section>
  );
}

function NextButton() {
  const swiper = useSwiper();
  return (
    <IconButton
      variant="outline"
      icon={<RightArrow />}
      onClick={() => swiper.slideNext()}
    >
      next
    </IconButton>
  );
}

function PrevButton() {
  const swiper = useSwiper();
  return (
    <IconButton
      variant="outline"
      icon={<LeftArrow />}
      onClick={() => swiper.slidePrev()}
    >
      prev
    </IconButton>
  );
}
