import React from "react";
import PropTypes from "prop-types";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function AppleStoreIcon({ className }) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("w-[1em] h-[1em]", className)}
    >
      <path
        d="m5.47437 20.299-.60228.9418c-.4231.726-1.36015.9747-2.09299.5556-.73272-.4192-.98393-1.3475-.56083-2.0735l.38218-.5577c.14634-.2083.50746-.568 1.22985-.5003 0 0 1.69995.1827 1.82298 1.058 0 0 .01668.288-.17891.5761zm16.99343-6.1278h-3.6144c-.2462-.0164-.3536-.1035-.396-.1542l-.0026-.0046-3.4548-5.60064c-.3803-.57618-.9842.89735-.9842.89735-.7211 1.64199.1024 3.50869.3893 4.07299l4.7228 7.8567c.423.7259 1.36.9748 2.093.5555.7327-.4192.9839-1.3475.5607-2.0735l-1.3437-2.3058c-.0261-.0559-.0714-.2075.2042-.2082h1.8257c.8462 0 1.5322-.6796 1.5322-1.5179s-.686-1.5177-1.5322-1.5177zm-8.7039 2.0635s.1929.9722-.5536.9722l-11.67813-.0001c-.846202 0-1.53217-.6796-1.53217-1.5179s.685968-1.5178 1.53217-1.5178h3.43514c.5546-.0318.68597-.349.68597-.349l.00317.0016 4.48395-7.69411-.0013-.00026c.0817-.14864.0136-.28914.0018-.31131l-1.48089-2.54104c-.4231-.72587-.17202-1.65431.56083-2.07333.73285-.419157 1.66986-.17055 2.09296.55546l.6868 1.17848.6856-1.17638c.4231-.72588 1.3601-.974749 2.093-.55546.7328.41915.9839 1.34732.5608 2.07333l-6.23967 10.70652c-.02728.0652-.03562.1675.16739.1855l2.04608.0001s2.1565.0693 2.4501 2.0635z"
        fill="currentColor"
      />
    </svg>
  );
}

AppleStoreIcon.propTypes = {
  className: PropTypes.string,
};

AppleStoreIcon.defaultProps = {
  className: "",
};
